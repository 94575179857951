import React from "react";

const FirstBlogPost = () => {
  return (
    <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-gray-900 antialiased">
      <div className="px-4 mx-auto max-w-screen-xl">
        <article className="mx-auto w-full max-w-2xl text-gray-300">
          {/* Article Header */}
          <header className="mb-12">
            <address className="flex items-center mb-6 not-italic">
              <div className="inline-flex items-center text-sm text-gray-100">
                <div>
                  <span className="text-xl font-bold text-gray-100">
                    Jared Watson
                  </span>
                  <p className="text-base text-gray-400">
                    <time dateTime="2024-11-26">November 26, 2024</time>
                  </p>
                </div>
              </div>
            </address>
            <h1 className="mb-6 text-3xl font-extrabold leading-tight text-gray-100 lg:text-4xl">
              Revolutionizing Scientific Research with{" "}
              <span className="bg-gradient-to-r from-orange-400 to-red-600 bg-clip-text text-transparent">
                Cure AI
              </span>
              : Your Ultimate Medical Research Companion
            </h1>
          </header>

          {/* Image under the first header */}
          <figure className="mb-12">
            <img
              src="/assets/first_blog_pic.webp"
              alt="Scientific Research Revolution"
              className="w-full h-auto rounded-lg shadow-lg"
            />
            <figcaption className="mt-2 text-sm text-gray-400 text-center">
              Cure AI transforming the scientific research landscape.
            </figcaption>
          </figure>

          {/* Article Content */}
          <section className="prose prose-invert prose-lg max-w-none">
            <p>
              In the ever-expanding universe of scientific literature, finding
              trustworthy, relevant, and high-quality evidence is more
              challenging than ever. Thousands of new papers are published
              daily, creating an overwhelming volume of information to sift
              through. For clinicians, researchers, and professionals in fields
              like healthcare, pharmaceuticals, and law, the stakes are
              high—accurate, evidence-based decisions hinge on accessing the
              right information quickly.
            </p>
            <p>
              Enter <strong className="text-orange-400">Cure AI</strong>: a
              patented research application designed to transform the way you
              navigate and manage scientific literature. Our goal is simple yet
              powerful—to{" "}
              <strong className="text-orange-500">
                optimize the scientific literature research workflow
              </strong>{" "}
              so you can focus on insights, not inefficiencies.
            </p>
            <hr className="my-12 border-gray-700" />

            <h2 className="text-2xl font-bold text-gray-100 mb-6">
              The Challenge:{" "}
              <span className="text-orange-400">
                Inefficient and Overwhelming Research Processes
              </span>
            </h2>
            <p>
              Traditional research methods are riddled with inefficiencies.
              Standard search engines often deliver vague, irrelevant, or
              incomplete results, forcing users to spend hours sifting through
              secondary data or outdated papers. For professionals who rely on
              cutting-edge evidence to make decisions, these inefficiencies can
              stall progress and limit outcomes.
            </p>
            <p>
              With Cure AI, we’ve reimagined the research process, addressing
              these challenges head-on. Our platform empowers users to{" "}
              <strong className="text-orange-500">
                quickly and effectively access high-quality evidence
              </strong>
              , tailored to their specific needs.
            </p>
            <hr className="my-12 border-gray-700" />

            <h2 className="text-2xl font-bold text-gray-100 mb-6">
              How Cure AI Optimizes the Research Workflow
            </h2>
            <p>
              Cure AI doesn’t just search—it transforms the way you interact
              with scientific literature by offering unparalleled precision,
              customization, and efficiency. Here’s how we do it:
            </p>

            <h3 className="text-xl font-semibold text-orange-400 mb-4 mt-4">
              1. Advanced Search Parameters
            </h3>
            <p>
              Cure AI allows you to refine your search with precision. Tailor
              your queries using specific parameters that go beyond generic
              keyword searches:
            </p>
            <ul className="list-disc list-inside space-y-2">
              <li>
                <strong className="text-gray-100">Filter by Journals:</strong>{" "}
                Focus on trusted, field-specific publications for higher
                relevance.
              </li>
              <li>
                <strong className="text-gray-100">
                  Select Primary Data Only:
                </strong>{" "}
                Skip summaries and reviews to access core studies directly.
              </li>
              <li>
                <strong className="text-gray-100">Specify Date Ranges:</strong>{" "}
                Search for studies published within a particular timeframe to
                ensure data is current and relevant.
              </li>
            </ul>

            {/* Image under Advanced Search Parameters */}
            <figure className="my-12">
              <img
                src="/assets/cure-advanced-search.jpeg"
                alt="Cure AI Advanced Search Parameters"
                className="w-full h-auto rounded-lg shadow-lg"
              />
              <figcaption className="mt-2 text-sm text-gray-400 text-center">
                Cure AI’s advanced search features streamline your workflow.
              </figcaption>
            </figure>

            <p>
              By giving you control over your search parameters, Cure AI
              eliminates irrelevant results, saving you time and ensuring you
              find exactly what you need.
            </p>
            <hr className="my-12 border-gray-700" />

            <h3 className="text-xl font-semibold text-orange-400 mb-4">
              2. AI-Powered Insights with Optimized Evidence Selection
            </h3>
            <p>
              At the heart of Cure AI is its{" "}
              <strong className="text-orange-400">
                AI-generated response engine
              </strong>
              , powered by advanced evidence selection logic. When you input a
              query, Cure AI doesn’t just surface articles—it synthesizes
              insights backed by rigorously cited studies, ranking evidence by
              relevance and quality.
            </p>
            <p>This optimized approach means you’ll always receive:</p>
            <ul className="list-disc list-inside space-y-2">
              <li>
                The{" "}
                <strong className="text-orange-500">
                  most credible evidence
                </strong>{" "}
                to support your decisions.
              </li>
              <li>
                A seamless blend of{" "}
                <strong className="text-orange-400">AI summaries</strong> and
                access to{" "}
                <strong className="text-orange-500">primary sources</strong>,
                ensuring transparency and depth.
              </li>
            </ul>
            <hr className="my-12 border-gray-700" />
            <h3 className="text-xl font-semibold text-orange-400 mb-4">
              3. Citation Management and Note-Taking Center{" "}
              <em className="text-gray-400">(Coming Soon!)</em>
            </h3>
            <p>
              Research is more than just finding relevant papers—it's about
              creating a cohesive workflow that connects discovery,
              organization, and actionable insights. Cure AI is excited to
              introduce its upcoming{" "}
              <strong className="text-orange-500">
                citation management and note-taking center
              </strong>
              , designed to make your research process truly comprehensive.
              Here's what you'll soon be able to do:
            </p>
            <ul className="list-disc list-inside space-y-2">
              <li>
                <strong>Create and Manage Projects:</strong> Group your research
                materials by topic, focus area, or project, keeping all your key
                resources neatly organized in one accessible hub.
              </li>
              <li>
                <strong>Take Notes and Annotate Sources:</strong> Record
                critical insights, observations, and key takeaways, directly
                linked to relevant studies and articles for easy reference.
              </li>
              <li>
                <strong>Integrate AI Responses:</strong> Seamlessly incorporate
                AI-generated summaries and evidence-backed insights into your
                notes, tailored to your research objectives.
              </li>
              <li>
                <strong>Streamlined Citation Management:</strong> Save,
                organize, and export citations effortlessly, simplifying the
                documentation process for publications, presentations, or
                reports.
              </li>
            </ul>
            <p>
              By bringing all these tools under one roof, Cure AI transforms
              into more than just a search engine—it becomes{" "}
              <strong className="text-orange-400">
                your all-in-one solution
              </strong>{" "}
              for scientific research. From discovery to documentation, Cure AI
              empowers you to manage every step of your research workflow with
              unparalleled efficiency.
            </p>
          </section>

          {/* CTA Section */}
          <footer className="mt-12">
            <div className="flex flex-col items-center gap-4">
              <p className="text-lg text-gray-300 text-center">
                Ready to streamline your research? Explore Cure AI today and
                transform the way you work with scientific literature!
              </p>
              <div className="flex flex-col md:flex-row gap-4">
                <a
                  href="/signup"
                  className="px-6 py-3 bg-gradient-to-br from-orange-500 to-red-500 text-gray-900 font-semibold rounded-lg shadow-lg hover:from-orange-400 hover:to-red-400 focus:ring-4 focus:ring-orange-300"
                >
                  Get Started Free
                </a>
                <a
                  href="/pricing"
                  className="px-6 py-3 bg-gray-700 text-gray-300 font-semibold rounded-lg shadow-lg hover:bg-gray-600 focus:ring-4 focus:ring-gray-500"
                >
                  View Plans
                </a>
              </div>
            </div>
          </footer>
          {/* Social Media Section */}
          <div className="mt-16 border-t border-gray-700 pt-8">
            <p className="text-lg text-gray-300 text-center mb-6">
              Follow us on social media for the latest updates and insights:
            </p>
            <div className="flex justify-center space-x-6">
              {/* Twitter Link */}
              <a
                href="https://x.com/askcureai"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center text-gray-300 hover:text-orange-500"
              >
                <svg
                  className="w-6 h-6 mr-2"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M24 4.557a9.942 9.942 0 0 1-2.828.775A4.932 4.932 0 0 0 23.337 3.2a9.86 9.86 0 0 1-3.127 1.195 4.921 4.921 0 0 0-8.384 4.482A13.957 13.957 0 0 1 1.67 3.149a4.913 4.913 0 0 0 1.523 6.574A4.9 4.9 0 0 1 .96 9.27v.062a4.922 4.922 0 0 0 3.946 4.827 4.902 4.902 0 0 1-2.212.084 4.923 4.923 0 0 0 4.6 3.417 9.866 9.866 0 0 1-6.102 2.104c-.396 0-.788-.023-1.176-.068A13.944 13.944 0 0 0 7.548 21c9.142 0 14.307-7.722 14.307-14.422 0-.22-.005-.439-.014-.657A10.18 10.18 0 0 0 24 4.557z" />
                </svg>
                Twitter
              </a>
              {/* LinkedIn Link */}
              <a
                href="https://www.linkedin.com/company/cureai"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center text-gray-300 hover:text-orange-500"
              >
                <svg
                  className="w-6 h-6 mr-2"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.761 0 5-2.239 5-5v-14c0-2.761-2.239-5-5-5zm-11.336 19h-3.328v-10h3.328v10zm-1.665-11.259c-1.067 0-1.934-.867-1.934-1.935 0-1.067.867-1.934 1.934-1.934s1.935.867 1.935 1.934c0 1.068-.868 1.935-1.935 1.935zm12.665 11.259h-3.328v-5.604c0-1.338-.027-3.061-1.866-3.061-1.866 0-2.152 1.459-2.152 2.968v5.697h-3.328v-10h3.192v1.364h.045c.445-.842 1.532-1.729 3.154-1.729 3.37 0 3.994 2.219 3.994 5.101v5.264z" />
                </svg>
                LinkedIn
              </a>
            </div>
          </div>
        </article>
      </div>
    </main>
  );
};

export default FirstBlogPost;
