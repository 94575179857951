import React from "react";
import { Link } from "react-router-dom";

const BlogCard = ({ title, description, author, date, category, slug }) => {
  return (
    <Link to={`/blog/${slug}`} className="block">
      <article className="p-6 bg-gradient-to-br from-gray-800 to-gray-900 rounded-lg border border-gray-700 shadow-lg hover:shadow-xl transition-shadow">
        <div className="flex justify-between items-center mb-4 text-gray-400">
          <span className="bg-orange-600 text-white text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded">
            {category}
          </span>
          <span className="text-sm">{date}</span>
        </div>
        <h2 className="mb-2 text-2xl font-bold tracking-tight text-gray-100">
          {title}
        </h2>
        <p className="mb-4 font-light text-gray-400">{description}</p>
        <div className="flex items-center space-x-4">
          <span className="font-medium text-gray-100">{author}</span>
        </div>
      </article>
    </Link>
  );
};

export default BlogCard;
