import React from "react";
import BlogCard from "./BlogCard";

const Blog = () => {
  const blogPosts = [
    {
      title:
        "Revolutionizing Scientific Research with Cure AI: Your Ultimate Medical Research Companion",
      description:
        "Discover how Cure AI optimizes scientific literature research workflows, transforming the way you navigate and manage scientific literature.",
      author: "Jared Watson",
      date: "November 26, 2024",
      category: "Article",
      slug: "revolutionizing-scientific-research-with-cure-ai",
    },
  ];

  return (
    <section className="bg-gray-900 px-6 py-20">
      <div className="mx-auto max-w-screen-xl">
        {/* Blog Header */}
        <div className="mx-auto max-w-screen-md text-center mb-12">
          <h2 className="text-4xl font-extrabold text-gray-100">
            <span className="bg-gradient-to-r from-orange-400 to-red-600 bg-clip-text text-transparent">
              Discover Insights and Innovations
            </span>
          </h2>
          <p className="mt-4 text-xl text-gray-400">
            Dive into the latest tips, tutorials, and innovations our team is
            exploring. Follow along to stay informed and inspired.
          </p>
        </div>

        {/* Blog Card Grid */}
        <div className="grid gap-10 lg:grid-cols-1 mb-16">
          {blogPosts.map((post, index) => (
            <BlogCard
              key={index}
              title={post.title}
              description={post.description}
              author={post.author}
              date={post.date}
              category={post.category}
              slug={post.slug}
            />
          ))}
        </div>

        {/* Newsletter Signup Section */}
        <div className="bg-gray-800 rounded-lg p-8 text-center">
          <h3 className="text-3xl font-extrabold text-gray-100 mb-4">
            <span className="bg-gradient-to-r from-orange-400 to-red-600 bg-clip-text text-transparent">
              Join Our Newsletter
            </span>
          </h3>
          <p className="text-lg text-gray-400 mb-8">
            Stay connected and get the latest updates directly to your inbox.
            Don’t miss out on exclusive content, insights, and early access to
            new features.
          </p>
          <form
            action="#"
            className="flex flex-col sm:flex-row items-center justify-center"
          >
            <input
              type="email"
              id="email"
              placeholder="Enter your email"
              required
              className="w-full sm:w-auto mb-4 sm:mb-0 px-4 py-2.5 text-gray-900 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
            <button
              type="submit"
              className="w-full sm:w-auto py-2.5 px-6 bg-gradient-to-br from-orange-500 to-red-500 text-white font-medium rounded-md hover:from-orange-400 hover:to-red-400 transition duration-150 focus:outline-none focus:ring-4 focus:ring-orange-500 ml-0 sm:ml-3"
            >
              Subscribe
            </button>
          </form>
          <p className="mt-4 text-sm text-gray-400">
            We care about your data privacy.{" "}
            <a href="/privacy" className="text-orange-500 hover:underline">
              Read our Privacy Policy
            </a>
            .
          </p>
        </div>
      </div>
    </section>
  );
};

export default Blog;
